import React from "react";
import {Link} from "gatsby";

import {useSiteMetadata} from "hooks";
import Container from "./Container";
import {ReactComponent as Logo} from "assets/images/cldt-logo-white-bg.svg";

const Header = () => {
    const {companyName, siteDescription} = useSiteMetadata();
    const fullDescription = `${companyName} - ${siteDescription}`;

    return (
        <header>
            <Container fluid={true}>
                <Link to="https://cldt.hr" title="Croatian Long Distance Trail | CLDT">
                    <Logo width={130}/>
                </Link>
                <Link to="/" title={fullDescription} style={{margin: '0 auto'}} className="text-center">
                    <h1>🗺️ {companyName} 🥾</h1>
                    <p>{siteDescription}</p>
                </Link>
            </Container>
        </header>
    );
};

export default Header;
