import React from "react";

import {useSiteMetadata} from "hooks";
import Container from "./Container";
import {FaGithub} from "react-icons/fa";
import {Link} from "gatsby";

const Footer = () => {
    const {authorName, authorUrl, companyName, companyUrl} = useSiteMetadata();

    return (
        <footer>
            <Container>
                <p className="copyright">
                    Copyright &copy; {new Date().getFullYear()}{" "}
                    <a href={authorUrl} target="_blank" rel="noreferrer">{authorName}</a>
                </p>
                <div className="copyright-right">
                    <Link to="/about/">About</Link>
                    <span style={{margin: '0 5px'}}> | </span>
                      <a
                          href={companyUrl}
                          title={`${companyName} Github`}
                          target="_blank"
                          rel="noreferrer"
                      >
                        <span className="visually-hidden">{companyName} Github</span>
                        <FaGithub />
                    </a>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
