import classNames from "classnames";
import React, {FC} from "react";

type ContainerProps = {
    children: React.ReactNode,
    className?: string;
    fluid?: boolean;
}

const Container: FC<ContainerProps> = ({children, className = '', fluid = false}: ContainerProps) => {
    return <div className={classNames({
        'container': true,
        'container-fluid': fluid
    }, className)}>{children}</div>;
};

export default Container;
